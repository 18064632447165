<template>
  <div class="">
    <center-title title="我的发票"></center-title>
    <div class="operation row bg-center mb20 center" v-if="false">
      <el-dropdown style="width: 18.4%;cursor: pointer;" trigger="click"
        @command="(e) => { listForm.bill_time = e, getDate(e), resetList(1) }">
        <div class="item">
          时间范围<i class="el-icon-arrow-down el-icon--right"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="" :class="{ primary: listForm.bill_time === '' }">全部</el-dropdown-item>
            <el-dropdown-item command="0" :class="{ primary: listForm.bill_time === '1' }">近三个月</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
      <div class="item" style="width: 17.8%">订单详情</div>
      <div class="item" style="width: 11.2%">金额</div>
      <div class="item" style="width: 10%"></div>
      <el-dropdown style="width: 14.2%;cursor: pointer;" trigger="click"
        @command="(e) => { listForm.bill_type = e, resetList(1) }">
        <div class="item">
          发票类型<i class="el-icon-arrow-down el-icon--right"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="" :class="{ primary: listForm.bill_type == '' }">全部</el-dropdown-item>
            <el-dropdown-item command="1" :class="{ primary: listForm.bill_type == '1' }">普通发票</el-dropdown-item>
            <el-dropdown-item command="2" :class="{ primary: listForm.bill_type == '2' }">增值税发票</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
      <el-dropdown style="width: 14.2%;cursor: pointer;" trigger="click"
        @command="(e) => { listForm.status = e, resetList(1) }">
        <div class="item">
          状态<i class="el-icon-arrow-down el-icon--right"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="" :class="{ primary: listForm.status === '' }">全部</el-dropdown-item>
            <el-dropdown-item command="1" :class="{ primary: listForm.status === '0' }">未申请</el-dropdown-item>
            <el-dropdown-item command="2" :class="{ primary: listForm.status === '1' }">等待开票</el-dropdown-item>
            <el-dropdown-item command="3" :class="{ primary: listForm.status === '2' }">已开票</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
      <div class="item" style="width: 14.2%">操作</div>
    </div>
    <el-form :inline="true" :model="listForm" class="demo-form-inline">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-select v-model="listForm.bill_time" placeholder="时间范围" @change="getDate">
              <el-option label="全部" value=""></el-option>
              <el-option label="近三个月" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="listForm.bill_type" placeholder="发票类型">
              <el-option label="全部" value=""></el-option>
              <el-option label="普通发票" value="1"></el-option>
              <el-option label="增值税发票" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="listForm.status" placeholder="状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="未申请" value="1"></el-option>
              <el-option label="等待开票" value="2"></el-option>
              <el-option label="已开票" value="3"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)">筛选</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" v-if="false">
      <div class="order-list xs">
        <div class="order-item" v-for="(item, index) in billList" :key="index">
          <div class="item-title bg-center">
            <span class="warn ml30 mr20">{{ item.create_time }}</span>
            <span class="warn">结算编号：</span>
            {{ item.settle_sn || '-' }}
          </div>
          <div class="row-between order-box">
            <div class="pro-list center flex1">
              <div class="pro-item row" v-for="(items, indexs) in item.order_goods" :key="indexs">
                <!-- <el-checkbox style="width: 11.5%" :label="item" :key="indexs"></el-checkbox> -->
                <div class="item-info row ml30" style="width: 51.5%">
                  <el-image :src="items.image" fit="cover" class="item-img"></el-image>
                  <router-link :to="`/prodetail?id=${items.goods_id}`" class="pro-title line2 ml30 left">
                    {{ items.goods_name }}
                  </router-link>
                </div>
                <div class="item-pirce" style="width: 19.5%">
                  <make-priceformat :price="items.total_pay_price" :subscriptSize="14" :firstSize="14" :secondSize="14"
                    color="#666666"></make-priceformat>
                </div>
                <div class="item-num" style="width: 17.5%">x{{ items.goods_num }}</div>
              </div>
            </div>
            <div class="pro-right row center" style="width: 42.6%">
              <div class="flex1">{{ (item.status == 6 && item.apply_bill == 1) ? (item.bill_info.type == 1 ? '普通发票' : '增值税发票') : '暂未开票' }}</div>
              <div class="flex1">
                <span class="normal" v-if="(item.status == 6 && item.apply_bill == 1)">{{ item.bill_info.status ? '已开票' : '等待开票' }}</span>
                <span class="price" v-else>未开票</span>
              </div>
              <div class="flex1">
                <router-link class="operation-btn"
                  :to="`/invoiceapply?id=${item.id}&order_sn=${item.order_sn}&order_amount=${item.order_amount}`"
                  v-if="(item.status == 6 || item.status == 1 || item.status == 5) && item.apply_bill == 0">申请开票</router-link>
                <!-- <router-link class="normal" :to="`/invoicedetail?data=${encodeURIComponent(JSON.stringify(item.bill_info))}`" v-else -->
                <router-link class="normal" :to="`/invoicedetail?id=${item.bill_id}`" v-else>发票详情</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-checkbox-group>
    <el-table :data="billList" style="width: 100%">
      <el-table-column align="center" prop="settle_sn" label="结算编号" width="220">
      </el-table-column>
      <el-table-column align="center" prop="deal_order_count" label="已对账成交订单数" width="160">
      </el-table-column>
      <el-table-column align="center" prop="after_sales_money" label="售后退款金额" width="160">
      </el-table-column>
      <el-table-column align="center" prop="entry_account_money" label="已对账金额" width="160">
      </el-table-column>
      <el-table-column align="center" prop="quota_money" label="已对账使用额度" width="160">
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="对账时间" width="200">
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态" width="120">
        <template slot-scope="scope">
          {{ getStatus(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status" label="操作" fixed="right" width="140">
        <template slot-scope="scope">
          <div class="column-center">
            <router-link class="operation-btn"
              :to="`/invoiceapply?id=${scope.row.id}&settle_sn=${scope.row.settle_sn}&entry_account_money=${scope.row.entry_account_money}`"
              v-if="(scope.row.status == 6 || scope.row.status == 1 || scope.row.status == 5) && scope.row.apply_bill == 0">申请开票</router-link>
            <!-- <router-link class="normal" :to="`/invoicedetail?data=${encodeURIComponent(JSON.stringify(item.bill_info))}`" v-else -->
            <router-link class="normal" :to="`/invoicedetail?id=${scope.row.bill_id}`" v-else>发票详情</router-link>
            <router-link class="normal mt10" :to="`/invoiceall?id=${scope.row.id}`">发票明细</router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="invoice-footer row-end">
      <!-- <div class="footer-left"> -->
      <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
      <!-- <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
      <!-- <button class="ml20 bg-white lighter cursor" @click="toApply">批量申请</button> -->
      <!-- </div> -->
      <make-pagination :orderObject="orderObject" @resetList="resetList"></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
import moment from 'moment';
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {

      value: "",
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      page_no: 1,
      page_size: 10,
      orderObject: {},
      billList: [],
      listForm: {
        bill_type: null,//发票类型
        status: null,//发票状态
        start_time: '',
        end_time: '',
        bill_time: null
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    getStatus(val) {
      let str = ''
      switch (parseInt(val)) {
        case 0:
          str = '待确认'
          break;
        case 1:
          str = '已确认'
          break;
        case 2:
          str = '已驳回'
          break;
        case 3:
          str = '已开票'
          break;
        case 4:
          str = '已收票'
          break;
        case 5:
          str = '已付款'
          break;
        case 6:
          str = '已收款'
          break;
        case 10:
          str = '已取消'
          break;
      }
      return str
    },
    resetList(e) {
      this.page_no = e;
      this.getBillLists();
    },
    getDate(e) {
      if (e === '0') {
        this.listForm.start_time = this.getRecentMonth(3)
        this.listForm.end_time = this.getRecentMonth(0)
      } else {
        this.listForm.start_time = ''
        this.listForm.end_time = ''
      }
    },
    getRecentMonth(n) {
      let month = moment(new Date()).subtract(n, 'months').format('YYYY-MM-DD');
      return month;
    },
    /**
     * @description 获取发票订单列表
     * @return void
     */
    getBillLists() {
      let { page_no, page_size } = this;
      this.$api
        .getBillListsApi({
          page_no,
          page_size,
          ...this.listForm
        })
        .then((res) => {
          this.billList = res.data.list;
          this.orderObject = res.data;
        });
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.billList : [];
      // this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.billList.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.billList.length;
    },
    onSubmit() { },
    toApply() {
      let arr = this.checkedCities.filter(el => !el.is_bill)
      if (arr.length == 0) {
        return
      }
    }
  },
  created() {
    this.getBillLists();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.operation {
  padding: 10px 0;

  .el-dropdown {
    font-size: 16px;
  }

  .item {
    /deep/ .el-select {
      .el-input__inner {
        background-color: transparent;
        border: none;
        font-size: 16px;
        text-align: center;
      }

      input::-webkit-input-placeholder {
        color: $-color-normal;
      }

      input::-moz-placeholder {
        color: $-color-normal;
      }

      input:-moz-placeholder {
        color: $-color-normal;
      }

      input:-ms-input-placeholder {
        color: $-color-normal;
      }

      .el-input__icon {
        color: $-color-normal;
      }

      .el-input__suffix {
        right: 15px;
      }
    }
  }
}

.order-list {
  .order-item {
    margin-bottom: 15px;

    /deep/ .el-checkbox__label {
      display: none;
    }

    .item-title {
      height: 48px;
      line-height: 48px;
    }

    .order-box {
      border: 1px solid $-color-center;
      border-top: none;
      align-items: flex-start;
    }

    .pro-list {
      .pro-item {
        padding: 20px 0;
        border-bottom: 1px solid $-color-center;
        border-right: 1px solid $-color-center;

        &:last-child {
          border-bottom: none;
        }

        .item-info {
          .item-img {
            width: 64px;
            height: 64px;
            flex-shrink: 0;
          }
        }
      }
    }

    .pro-right {
      padding-top: 44px;

      .operation-btn {
        width: 72px;
        height: 28px;
        border: 1px solid $-color-primary;
        border-radius: 4px;
        display: inline-block;
        color: $-color-primary;
        line-height: 26px;
      }
    }
  }
}

.invoice-footer {
  button {
    width: 80px;
    height: 32px;
    border: 1px solid $-color-warn;
    border-radius: 4px;
  }
}



/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 10px 0px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>